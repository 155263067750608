/* You can add global styles to this file, and also import other style files */
/*@import '~https://fonts.googleapis.com/icon?family=Material+Icons';*/
/*@import '~material-design-lite/material.min.css';*/
/*@import "~@angular/material/prebuilt-themes/indigo-pink.css";*/
/*@import "~@angular/material/prebuilt-themes/indigo-pink.css";*/
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

.demo-card-wide.mdl-card {
    /* width: 512px; */
    display: inline-block;
    margin: 4px;
}


.room-card-wide > .mdl-card__title {
 width : 100%;
}

.demo-card-wide > .mdl-card__title {
  color: #000;
  height: 82px;
  /*background: url('../assets/demos/welcome_card.jpg') center / cover;*/
}
.demo-card-wide > .mdl-card__menu {
  color: #fff;
}

.mat-dialog-container .md-dialog-container {
  position: fixed;
  left: 50% !important;
  top: 50%;
  background-color: white;
  z-index: 100000;

}

.mdl-card {
  margin-bottom: 10px;
}
/*
body{
 font-family:   Montserrat,"Helvetica Neue",Arial,sans-serif !important

}
*/
body {
  font-family: "Source Sans Pro", sans-serif !important;}



.mat-radio-label-content, .mat-radio-label,   label > div.mat-radio-label-content {
  white-space: pre-line !important;
}


label.mat-radio-label > div.mat-radio-label-content {
  white-space: pre-line !important;
}



.mdl-radio {
  height: auto !important;
}


.mdl-layout__header .mdl-layout__drawer-button {
  color: grey !important;
}
